body {
  margin: 0;
  /* overflow-y: hidden; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.kt-divider {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-right: 32px;
  margin-left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kt-divider > span:first-child {
  width: 100%;
  height: 1px;
  flex: 1 1;
  background: #EBECF1;
  display: inline-block;
}

.kt-divider > span:not(:first-child):not(:last-child) {
  padding: 0 2rem;
}

.kt-divider > span:last-child {
  width: 100%;
  height: 1px;
  flex: 1 1;
  background: #EBECF1;
  display: inline-block;
}
.sticky {
  position: absolute;
  right: -30px;
  top: 320px;
  z-index: 150;
  transform: rotate(7deg);
  width: 200px;
  min-height: 150px;
  margin: -10px 10px 10px;
  padding: 10px;
  font-family: "Comic Sans MS", "Comic Sans", "Chalkboard SE", "Comic Neue", cursive;
  font-size: 14px;
  color: #000;
  background: rgba(255, 255, 51, 0.9);
  box-shadow: -2px 2px 2px rgba(0,0,0,0.3);
}
.sticky:before, .sticky:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
}
.sticky:before {
  border-top: solid 8px #fff;
  border-right: solid 8px #fff;
  border-left: solid 8px transparent;
  border-bottom: solid 8px transparent;
}
.sticky:after {
  border-bottom: solid 8px #dddd33;
  border-left: solid 8px #dddd33;
  border-right: solid 8px transparent;
  border-top: solid 8px transparent;
}

.sticky2 {
  width: 200px;
  min-height: 150px;
  margin: 10px 10px 10px;
  padding: 10px;
  font-family: "Comic Sans MS", "Comic Sans", "Chalkboard SE", "Comic Neue", cursive;
  font-size: 14px;
  color: #000;
  position: relative;
  background: rgba(255, 255, 51, 0.9);

  box-shadow: -2px 2px 2px rgba(0,0,0,0.3);
}
.sticky2:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
}
.sticky2:before {
  border-top: solid 8px #fff;
  border-right: solid 8px #fff;
  border-left: solid 8px transparent;
  border-bottom: solid 8px transparent;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #fff #444;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #424242;
}
*::-webkit-scrollbar-thumb {
  background-color:#ccc;
  border-radius: 20px;
  border: 1px solid #222;
}
